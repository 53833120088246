import { IncomingCallData } from '@/lib/types/rtk-types/call-center';
import { createSlice } from '@reduxjs/toolkit';
import { Device } from '@twilio/voice-sdk';

interface QueueCall {
  id: number;
  call_sid?: string;
  customer_store_id: number;
  name: string;
  phone_number: string;
  start_time: string;
  timestamp: string;
  action: string;
  claim_call_link: string;
  status: string;
}

interface Calls {
  user_id: number;
  name: string;
  agent: string;
  phone_number: string;
  call_status: string;
  call_type: string;
  call_start_time: string;
  call_end_time: string | null;
  on_hold_time: string | null;
}

interface CallCenterState {
  incomingCallStatus: boolean;
  incomingCallData: IncomingCallData | null;
  activeCall: QueueCall | null;
  twilioToken: string | null;
  callStatus: string | null;
  callActive: boolean;
  accessToken: string | null;
  identity: string | null;
  incomingQueue: QueueCall[];
  onHoldQueue: QueueCall[];
  widgetVisibility: boolean;
  twilioDevice: Device | null;
  callCenterName: string | null;
  ivrCurrentCallsCounter: number;
}

const initialState: CallCenterState = {
  incomingCallStatus: false,
  incomingCallData: null,
  activeCall: null,
  twilioToken: null,
  callStatus: null,
  callActive: false,
  accessToken: null,
  identity: null,
  incomingQueue: [],
  onHoldQueue: [],
  widgetVisibility: false,
  twilioDevice: null,
  callCenterName: null,
  ivrCurrentCallsCounter: 0
};

const callCenterSlice = createSlice({
  name: 'callCenter',
  initialState,
  reducers: {
    updateCallCenterName: (state, action) => {
      state.callCenterName = action.payload;
    },
    setIncomingCallData: (state, action) => {
      state.incomingCallData = action.payload;
      if (action.payload === null) {
        state.widgetVisibility = false;
        state.incomingQueue = [];
        state.activeCall = null;
      }
    },

    updateIsCallActive: (state, action) => {
      state.callActive = action.payload;
      state.widgetVisibility = action.payload;
    },
    updateIncomingCallStatus: (state, action) => {
      state.incomingCallStatus = action.payload;
    },
    setTwilioToken: (state, action) => {
      state.twilioToken = action.payload;
    },

    updateCallStatus: (state, action) => {
      state.callStatus = action.payload;
    },
    //create a new reducer to update device status
    twilioDevice: (state, action) => {
      state.twilioDevice = action.payload;
    },
    identity: (state, action) => {
      state.identity = action.payload;
    },
    addOnHoldQueue: (state, action) => {
      state.onHoldQueue.push(action.payload);
    },
    addCallToQueue: (state, action) => {
      //add the payload to the end of the queue
      state.widgetVisibility = true;
      state.activeCall = action.payload;

      state.incomingQueue.push(action.payload);
      //log the queue

      // Set the active call to the first in the queue
      state.activeCall = state.incomingQueue[0];
    },
    removeCallFromQueue: (state, action) => {
      //remove the payload from the queue
      const modifiedArray =
        state.incomingQueue.filter((call) => call.id !== action.payload) ?? [];

      state.incomingQueue = modifiedArray;

      // Set the active call to the first in the queue or null if the queue is empty
      state.activeCall =
        state.incomingQueue.length > 0 ? state.incomingQueue[0] : null;

      if (state.incomingQueue?.length === 0) {
        state.incomingQueue = [];
        state.widgetVisibility = false;
      }
    },
    addIvrCurrentCallsCounter: (state) => {
      // Increment the ivrCurrentCallsCounter by 1
      state.ivrCurrentCallsCounter += 1;
    },
    subtractIvrCurrentCallsCounter: (state) => {
      // Decrement the ivrCurrentCallsCounter by 1, with a check to prevent it from going below 0
      if (state.ivrCurrentCallsCounter > 0) {
        state.ivrCurrentCallsCounter -= 1;
      }
    }
  }
});

export const {
  updateIncomingCallStatus,
  setTwilioToken,
  identity,
  updateCallStatus,
  setIncomingCallData,
  updateIsCallActive,
  twilioDevice,
  addCallToQueue,
  removeCallFromQueue,
  updateCallCenterName,
  addOnHoldQueue,
  addIvrCurrentCallsCounter,
  subtractIvrCurrentCallsCounter
} = callCenterSlice.actions;

export default callCenterSlice.reducer;
