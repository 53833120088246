import { Logo } from '@/assets/logos/logo';
import Seo from '@/components/_shared/seo';
import { gtm } from '@/lib/gtm/gtm';
import { social } from '@/lib/utils/social-urls';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useEffect } from 'react';

const Maintenance: NextPage = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Head>
        <title>Maintenance | RipeMetrics</title>
      </Head>
      <Seo />
      <div className="bg-white">
        <main className="mx-auto w-full max-w-7xl px-6 pt-10 pb-16 sm:pb-24 lg:px-8">
          <span className="sr-only">RipeMetrics</span>
          <div className="flex justify-center ">
            <Logo variant="primary" />
          </div>

          <div className="mx-auto mt-20 max-w-4xl text-center sm:mt-24">
            <p className="text-base font-semibold leading-8 text-orange-600">
              RipeMetrics AI Platform Upgrade in Progress
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              We&apos;re making things even better!
            </h1>
            <p className="mt-4 max-w-4xl text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
              RipeMetrics is currently undergoing scheduled maintenance to bring
              you an enhanced AI experience. We&apos;re working hard to upgrade
              our platform and introduce exciting new features to help you
              achieve even greater business success.
            </p>
          </div>
        </main>
        <footer className="border-t border-gray-100 py-6 sm:py-10">
          <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
            <p className="text-sm leading-7 text-gray-400">
              &copy; RipeMetrics, Inc. All rights reserved.
            </p>
            <div className="hidden sm:block sm:h-7 sm:w-px sm:flex-none sm:bg-gray-200" />
            <div className="flex gap-x-4">
              {social.map((item, itemIdx) => (
                <a
                  key={itemIdx}
                  href={item.href}
                  className="text-gray-400 hover:text-orange-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon
                    className="size-6"
                    aria-hidden="true"
                  />
                </a>
              ))}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Maintenance;
