import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
// import globalThis from 'globalthis';

export interface DarkMode {
  responsiveFontSizes?: boolean;
  theme: 'light' | 'dark';
}

export interface DarkModeContextValue {
  darkMode: DarkMode;
  saveDarkMode: (update: DarkMode) => void;
}

interface DarkModeProviderProps {
  children?: ReactNode;
}

// added globalThis to avoid error and help build complete
//@ts-ignore

const initialDarkMode: DarkMode = {
  responsiveFontSizes: true,
  theme: 'light'
};

export const restoreDarkMode = (): DarkMode | null => {
  const darkMode = null;

  try {
    // const storedData: string | null =
    //   globalThis.localStorage.getItem('darkMode');
    // if (storedData) {
    //   darkMode = JSON.parse(storedData);
    // } else {
    //   darkMode = {
    //     responsiveFontSizes: true,
    //     theme: globalThis.matchMedia('(prefers-color-scheme: dark)').matches
    //       ? 'dark'
    //       : 'light'
    //   };
    // }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return darkMode;
};
//@ts-ignore
export const storeDarkMode = (darkMode: DarkMode): void => {
  //@ts-ignore
  window?.localStorage.setItem('darkMode', JSON.stringify(darkMode));
};
//@ts-ignore
export const DarkModeContext = createContext<DarkModeContextValue>({
  darkMode: initialDarkMode
  // saveDarkMode: (update: DarkMode) =>
});

export const DarkModeProvider: FC<
  React.PropsWithChildren<DarkModeProviderProps>
> = (props) => {
  const { children } = props;
  const [darkMode, setDarkMode] = useState<DarkMode>(initialDarkMode);

  useEffect(() => {
    const restoredDarkMode = restoreDarkMode();

    if (restoredDarkMode) {
      setDarkMode(restoredDarkMode);
    }
  }, []);
  //@ts-ignore
  const saveDarkMode = (updatedDarkMode: DarkMode): void => {
    setDarkMode(updatedDarkMode);
    storeDarkMode(updatedDarkMode);
  };

  return (
    <DarkModeContext.Provider
      value={{
        darkMode,
        saveDarkMode
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

DarkModeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const DarkModeConsumer = DarkModeContext.Consumer;
